<template>
  <div class="custom-height-100p" style="overflow: auto">
    <div class="hall-box" v-if="data && data.length > 0">
      <div
        v-for="(hall, hallIndex) in data"
        :key="hall.hall_id"
        class="hall-content-box"
      >
        <div class="custom-padding-10 custom-height-100p hall-content">
          <div
            :class="`hall-name-box ${hall.hall_status === 'pause' || hall.effective === false ? 'hall-name-pause' : hall.status === 'funeral' ? 'hall-name-red' : (hall.nodes && hall.nodes.length > 0 ? (hall.is_farewell ? 'hall-name-red' : 'hall-name-active'): '')}` "
          >
            <div class="hall-name">
              <div>{{ hall.hall_name }}</div>
              <div v-if="hall.nodes && hall.nodes.length > 0">
                <div>逝者：{{ hall.death_name }}</div>
                <div style="color:#F7E808;">进厅时间：{{ hall.to_hall_time }}</div>
                <div>出厅时间：{{ hall.leave_hall_time }}</div>
              </div>
              <div v-else-if="hall.hall_status === 'pause' || hall.effective === false">
                <div>暂停使用</div>
              </div>
              <div v-else>
                <div>空闲</div>
              </div>
            </div>
          </div>

          <div
            v-if="hall.nodes && hall.nodes.length > 0"
            style="overflow: auto;"
            class="custom-height-100p custom-margin-top-10"
            :ref="`${'nodeActive'+hallIndex}`"
          >
            <div
              class="node-day"
              v-for="(item, index) in hallNodes[hallIndex]"
              :key="index"
              v-show="item.is_today"
            >
              <div class="day">
                <a-icon type="left" @click="toLastDay(hallIndex, index)" />
                <div class="day-content">
                  <span>{{ item.name }}</span>
                  <span>{{ item.date }}</span>
                </div>
                <a-icon type="right" @click="toNextDay(hallIndex, index)" />
              </div>

              <div
                v-for="(node, index) in item.processes"
                :key="index"
                :class="{'node': true,'node-twinkle': isOverTime(node.slug, hall)}"
                :style="{backgroundColor: node.is_next ? '#ffd794ff' : '', color: node.is_next ? '#323232FF' : ''}"
              >
                <div class="node-name">{{ node.name }}</div>
                <div>{{ node.is_finished ? '已完成' : '' }}</div>
                <div class="node-remark">{{ node.remark }}</div>
              </div>
            </div>
          </div>
          <no-data class="custom-card-box custom-margin-top-10" tip="暂无进厅数据" v-else />
        </div>
      </div>
    </div>
    <no-data class="custom-card-box" v-else />
  </div>
</template>

<script>
import { findComprehensiveHalls } from '@/api/hall'
import NoData from '@/views/home/NoData'

export default {
  name: 'DispatchScreenHome',
  components: {
    NoData
  },
  data() {
    return {
      data: [],
      currentDay: 1,
      hallNodes: []
    }
  },
  created() {
    this.fetchData()
    this.loopFetchData()
  },
  destroyed() {
    clearInterval(this.loopFetchDataTimer)
  },
  methods: {
    isOverTime(slug, hall) {
      return (slug === 'TributeCeremony' && hall.is_tribute_ceremony_over_time === true) ||
          (slug === 'LibationBox' && hall.is_libation_box_over_time === true) ||
          (slug === 'ThankfulCeremony' && hall.is_thankful_ceremony_over_time === true) ||
          (slug === 'LeaveFuneral' && hall.is_funeral_ceremony_over_time === true)
    },
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.fetchData, 60000)
    },

    fetchData() {
      this.hallNodes = []
      findComprehensiveHalls().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          for (const hall of this.data) {
            this.hallNodes.push(hall.nodes)
          }

          this.$nextTick(() => {
            this.hallNodes.forEach((hallNodes, index) => {
              if (hallNodes) {
                hallNodes.forEach((hallNode) => {
                  if (hallNode.is_today && hallNode.processes) {
                    hallNode.processes.forEach((node, nodeIndex) => {
                      if (node.is_next) {
                        this.$refs[`nodeActive${index}`][0].scrollTo(0, nodeIndex * 110)
                      }
                    })
                  }
                })
              }
            })
          })
        }
      })
    },

    toLastDay(hallIndex, index) {
      const nodes = this.hallNodes[hallIndex]
      if (index > 0) {
        nodes[index].is_today = false
        nodes[index - 1].is_today = true
      }
    },

    toNextDay(hallIndex, index) {
      const nodes = this.hallNodes[hallIndex]
      if (index < 2) {
        nodes[index].is_today = false
        nodes[index + 1].is_today = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hall-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;

  .hall-content-box {
    flex-basis: 14.28%;
    height: 50%;

    .hall-content {
      display: flex;
      flex-direction: column;
    }

    .hall-name-box {
      padding: 10px;
      border-radius: 6px;
      text-align: center;
      flex-direction: column;
      background: #409373;
      border: 2px solid #409373;
      .hall-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;
      }
    }

    .hall-name-active {
      background: rgba(206, 123, 58, 100%);
      border: 2px solid rgba(206, 123, 58, 100%);
    }

    .hall-name-red {
      background: red;
      border: 2px solid red;
    }

    .hall-name-idle {
      background: #409373;
      border: 2px solid #409373;
    }

    .hall-name-pause {
      background: rgba(170, 170, 170, 100%);
      border: 2px solid rgba(170, 170, 170, 100%);
    }

    .node-day {
      .day {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-size: 24px;
        border-radius: 10px;
        background-color: #2d63ab;
        align-content: center;
      }

      .day-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
      }

      .node {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        padding: 10px;
        min-height: 90px;
        font-size: 20px;
        border-radius: 10px;
        background-color: #37543e;
        flex-direction: column;

        .node-name {
          font-size: 24px;
        }

        .node-remark {
          white-space: pre-wrap;
        }

      }

      .node-twinkle {
        animation: twinkle 1s alternate infinite;
      }

      @keyframes twinkle {
        from { background: #fc0000; }
        to { background: #ec808d; }
      }
    }
  }
}
</style>

